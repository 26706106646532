<template>
  <div class="discord">
    Ordinals
  </div>
</template>

<script>
export default {
  name: 'Discord',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
